import React, { Fragment, useEffect, useState } from 'react';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import DriveFileRenameOutlineIcon from '@mui/icons-material/DriveFileRenameOutline';
import { Box, Checkbox, FormControlLabel, IconButton, TextField, Typography } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { arr } from '../jsonConstant';
import { useSelector } from 'react-redux';
import { formatDate } from '../../helpers/utilities';
import './centralComplaintsAccordion.css';
import { updateCentralReportingData } from '../../../_services';
import { toast } from 'react-toastify';

export const CentralComplaintsAccordion = ({setNoIssueCheck}) => {
    const navigate = useNavigate();
    const [isChecked, setIsChecked] = useState(false)
    const complaintsData = useSelector(state => state.centralComplaintsData?.listData);
    const reportingData = useSelector(state => state.centralReportingData?.reportingData);
    const activeHospitalId = reportingData?.data ? reportingData?.data[0]?.hospitalId : null
    const appointmentId = reportingData?.data ? reportingData?.data[0]?.appointment_id || reportingData?.data[0]?._id : null;
    const userId = useSelector(state => state.authentication.user.userId)
    const storeIPDValue = (reportingData?.data && reportingData?.data[0]?.isComplaintPresent) ? reportingData?.data[0]?.isComplaintPresent?.value : null

    const handleRaiseIssue = () => {
        navigate(`/central/${activeHospitalId}/${appointmentId}/complaints`, { state: { arrValue: arr, editData: null } })
    }

    const updateComplaintsDetails = (item) => {
        const arrValue = arr.filter(ele => ele?.value?.toLowerCase() === item?.issue?.toLowerCase())
        navigate(`/central/${activeHospitalId}/${appointmentId}/complaints`, { state: { arrValue, editData: item || null } });
    };

    useEffect(()=>{
        if(storeIPDValue!=null){
            setIsChecked(storeIPDValue)
            setNoIssueCheck(storeIPDValue)
        }
    },[storeIPDValue])

    const handleNoIPDIssue = async()=>{
        let payload = 
            {
                "appId": appointmentId,
                "updateObj": {
                    "isComplaintPresent": {
                        "value": !isChecked,
                        "actionTakenBy": userId,
                        "dateTimeIso": new Date().toISOString()
                    }
                }
            }
        
        setIsChecked(!isChecked)
        setNoIssueCheck(!isChecked)
        let ipdRes = await updateCentralReportingData(payload)
        console.log({ipdRes})
        if(ipdRes && ipdRes.status === 200){
            toast.success('Updated Successfully')
        }
        else{
            toast.error(ipdRes.message)
        }
    }
    return (
        <div className='complaintsCardHolderContainer'>
            <div className='complaintsCardCheckBox' onClick={handleNoIPDIssue}>
                <Checkbox
                   sx={{
                    color: "#3476CD",
                    padding:'9px 0px 9px 6px',
                    "&.Mui-checked": {
                        color: "#3476CD",
                    },
                    "& .MuiSvgIcon-root": {
                        height: '20px',
                        width: '20px',
                    },
                    "& .PrivateSwitchBase-input": {
                        height: '20px',
                        width: '20px',
                    },
                    
                }}
                    onClick={(e) => e.stopPropagation()}
                    checked={isChecked}
                    onChange={(e) => {
                        e.stopPropagation();
                        handleNoIPDIssue()
                        }
                    }
                />
                <span className='complaintsCardIssueText'>No Complaint In IPD Journey</span >
            </div>
            <IconButton onClick={handleRaiseIssue} sx={{ marginBottom: '12px', borderRadius: 'unset' }}>
                <AddCircleOutlineIcon sx={{ color: '#3476CD', height: '18px', width: '18px' }} />
                <span className='complaintsCardIssueText'>Raise an Issue</span>
            </IconButton>
            <div className='complaintsCardMainContainer'>
                {complaintsData && complaintsData?.length > 0 && complaintsData[0]?.complaints?.length > 0 ? complaintsData[0].complaints?.map((item, index) =>
                    <div key={item._id} className='complaintsCardContainer'>
                        <div className='complaintsCardHeaderContainer'>
                            <span className='complaintsCardHeaderHeading'>{item.issue || "NA"}</span>
                            <IconButton onClick={() => updateComplaintsDetails(item)}>
                                <DriveFileRenameOutlineIcon sx={{ color: '#3476CD', height: '18px', width: '18px' }} />
                            </IconButton>
                        </div>
                        <div className='complaintsCardBodyContainer'>
                            <div className='complaintsCardDetailsContainer'>
                                {
                                    item.types && item.types.length > 0 && item.types.map((ele, ind) => (
                                        <Fragment key={ele.type || ("key" + index)}>
                                            {ele.type ? <span className='complaintsCardIssueName'>{ind + 1}. {ele.type}</span> : ""}
                                            <div className='complaintsCardChipsContainer'>
                                                {ele.subtype && ele.subtype?.length > 0 && ele.subtype.map(val =>
                                                    <div key={val} className='complaintsCardChips'>{val}</div>
                                                )}
                                            </div>
                                        </Fragment>
                                    )
                                    )
                                }
                                {item.remark ? <div className='complaintsCardRemarksContainer'>
                                    <span className='complaintsCardRemarksHeading'>Remarks:</span>
                                    <span className='complaintsCardRemarksDescription'>{item.remark}</span>
                                </div> : ""}
                            </div>
                            <div className='complaintsCardFooterContainer'>
                                <div className='complaintsCardTimeContainer'>
                                    <span className='complaintsCardTimeText'>Time : </span>
                                    <span className='complaintsCardTimerValue'>{item._created_at ? formatDate(item._created_at, "DD MMM, hh:mm A") : 'NA'}</span>
                                </div>
                                <div className='complaintsCardUserContainer'>
                                    <span className='complaintsCardUserText'>User : </span>
                                    <span className='complaintsCardUserName'>{item.createdByUser || item.updatedByUser || "NA"}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                )
                    : <span className='emptyEquipmentData'>No Complaints Available</span>
                }
            </div>
        </div>
    )
}
