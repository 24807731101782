const OAC_HOSPITAL = [
    {
        "label": "Yes",
        "value": "Yes"
    },
    {
        "label": "No",
        "value": "No"
    },
]

const FILE_STATUS = [
    {
        "label": "File dispatched to TPA",
        "value": "File dispatched to TPA"
    },
    {
        "label": "File handed over to TPA/INS office",
        "value": "File handed over to TPA/INS office"
    },
    {
        "label": "Online Submission",
        "value": "Online Submission"
    },
    {
        "label": "Settled",
        "value": "Settled"
    },
]

const FILE_FOR_CASHLESS = ["dispatchedFile", "insOfficeFile", "onlineSubmissionFile", "settledFile"]

const POD_STATUS = [
    {
        "label": "Pending",
        "value": "Pending"
    },
    {
        "label": "Reimbursement File Dispatched",
        "value": "Reimbursement File Dispatched"
    },
]

const POD_STATUS_FILES = ['fileForPending','fileForReimbursement']

const UPLOADED_FILE_NUMBER = [
    {
        "label": "POD Number",
        "value": "podNumber"
    },
    {
        "label": "Acknowledgment Number",
        "value": "acknowledgmentReceiptNumber"
    },
    {
        "label": "Online submission receipt ",
        "value": "onlineSubmissionReceipt"
    },
    {
        "label": "Settlement letter",
        "value": "settlementLetter"
    },
]

const UPLOADED_FILE_DATE = [
    {
        "label": "Dispatch Date",
        "value": "dispatchDate"
    },
    {
        "label": "Acknowledgement Date",
        "value": "acknowledgementDate"
    },
    {
        "label": "Online Submission Date",
        "value": "onlineSubmissionDate"
    },
    {
        "label": "Settlement Date",
        "value": "settlementDate"
    },
]
export {OAC_HOSPITAL, FILE_STATUS,
    POD_STATUS,
    UPLOADED_FILE_DATE,
    UPLOADED_FILE_NUMBER,
    POD_STATUS_FILES,
    FILE_FOR_CASHLESS,
}