import React, { useEffect, useState } from 'react';
import { FormControl, FormHelperText, InputAdornment, OutlinedInput} from '@mui/material';
import './centralInputField.css';

export const CentralInputField = (props) => {
    let {value='', placeholder,label, handleOnChange = ()=>{}, type = 'text', error = false, startAdornment='', disabled=false, helperText ='', name='', width = '47%',jsonDetails= '', sx={}, handleGlobalChanges=()=>{}, maxLength=null, endAdornment=''} = props;
    const [inputValue, setInputValue] = useState(jsonDetails?.value || value || '')
    // useEffect(() => {
    //   if((jsonDetails && ((jsonDetails.key==="amount_collected_admission" && (jsonDetails.value || jsonDetails.value==0)) || (jsonDetails.key ==="total_collected_at_discharge" && (jsonDetails.value|| jsonDetails.value==0))))){
    //     setInputValue(jsonDetails?.value);
    //   } else {
    //     setInputValue(inputValue);
    //   }
    // });
    useEffect(() => {
      if (jsonDetails && jsonDetails?.value !== undefined && jsonDetails?.value !== null) {
        setInputValue(typeof jsonDetails.value === 'string'? jsonDetails.value.trim():jsonDetails.value);
      } else {
        setInputValue(typeof jsonDetails.value === 'string' ? value.trim(): value);
      }
    }, [jsonDetails?.value, value]);

    const onChange = (event) =>{
      const value  = event.target.value
      setInputValue(value)
      handleGlobalChanges(jsonDetails &&jsonDetails?.postKey)
      handleOnChange(event)
    }

    if(jsonDetails && ((jsonDetails.key==="amount_collected_admission" && (jsonDetails.value|| jsonDetails.value==0)) || (jsonDetails.key ==="total_collected_at_discharge" && (jsonDetails.value|| jsonDetails.value==0)))){
        disabled = true
    }
    
  return (
    <FormControl sx={{ width: {width} , maxWidth:'350px', marginTop:'20px', ...sx}} variant="outlined" disabled={disabled || (jsonDetails && jsonDetails?.disable)}>
    <FormHelperText id="input-text-field-label" className='inputFieldLabel'>{label || (jsonDetails &&jsonDetails?.label)}</FormHelperText>
    <OutlinedInput
      id={name}
      startAdornment={startAdornment ? <InputAdornment position="start"className='inputStartAdornment'>{startAdornment}</InputAdornment>:''}
      endAdornment={endAdornment?
        <InputAdornment position="end" className='inputEndAdornment'>
          {endAdornment}
        </InputAdornment>:''
      }
      onChange={onChange}
      name={name || (jsonDetails &&jsonDetails?.postKey)}
      margin="dense"
      value={value || inputValue  || (typeof(inputValue) === "number" ? inputValue : '')}
      size="small"
      placeholder={placeholder || 'Enter Input'}
      type={type}
      error={error}
      className='inputFieldStyle'
      onWheel={(e) => e.target.blur()}
      inputProps={maxLength ? { maxLength } : {}}
      sx={{height:'30px',fontSize:'12px', color:'#393939', lineHeight:'18px', paddingLeft:'10px'}}
    />
   {(error && helperText) ? <FormHelperText error id="" className='inputFieldError'>
      {helperText}
    </FormHelperText> :''}
  </FormControl>
  )
}
