import React, { useEffect, useState } from 'react'
import './centralPostDischarge.css'
import { FILE_FOR_CASHLESS, FILE_STATUS, OAC_HOSPITAL, POD_STATUS, POD_STATUS_FILES, UPLOADED_FILE_DATE, UPLOADED_FILE_NUMBER } from './constant';
import { CentralSelect } from '../CentralSelect/CentralSelect';
import { CentralInputField } from '../CentralInputField/CentralInputField';
import { formatDate } from '../../helpers/utilities';
import { Box, TextField, Typography } from '@mui/material';
import CentralDateTimePicker from '../CentralDateTimePicker/CentralDateTimePicker';
import { toast } from 'react-toastify';
import { CentralFileUpload } from '../CentralUploadFile/CentralUploadFile';
import { getTokenFromLocal } from '../../../_helpers/handleResponseMiddleware';
import { useSelector } from 'react-redux';
import { updateCentralReportingData } from '../../../_services';

export const CentralPostDischarge = () => {
    const [formData, setFormData] = useState({
        oacHospital: '',
        fileStatus: '',
        podNumber: '',
        acknowledgmentReceiptNumber: '',
        onlineSubmissionReceipt: '',
        settlementLetter: '',
        dispatchDate: '',
        acknowledgementDate: '',
        onlineSubmissionDate: '',
        settlementDate: '',
        updatedTime: '',
        updatedBy: '',
        remarks:'',
        fileProof:[],
        defaultFileProof:[],
        reimbursementPodStatus:'Pending',
        reimbursementPodNumber:'',
        reimbursementPodCourierName:'',
        fileForPending:[],
        fileForReimbursement:[],
        dispatchedFile:[],
        insOfficeFile:[],
        onlineSubmissionFile:[],
        settledFile:[],
    });
    const [oacHospitalIndex, setOacHospitalIndex] = useState(null)
    const [initialFormData, setInitialFormData] = useState({});
    const [isClearedFiles, setIsClearedFiles] = useState(false)
    const reportingData = useSelector(state=>state.centralReportingData?.reportingData);
    const leadId = reportingData?.data ? reportingData?.data[0]?.lead_id: null
    const appId = reportingData?.data ? reportingData?.data[0]?.appointment_id || reportingData?.data[0]?._id : null ;
    const payment_mode = reportingData?.data ? reportingData?.data[0]?.payment_mode : null ;
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    let userName = user ? user.username || user?.name : null;
    let currentDate = new Date();
    let currentISODate = currentDate.toISOString();

    useEffect(() => {
      if(reportingData?.data && reportingData?.data[0]?.postDischargeDocuments && Object.keys(reportingData?.data[0]?.postDischargeDocuments)?.length>0){
        const temp = reportingData?.data[0]?.postDischargeDocuments
        let updatedData
        if(payment_mode?.toLowerCase() === 'cashless'){
            const fileStatusIndex = FILE_STATUS.findIndex((status) => status.value === temp.fileStatus);
            const podStatusFile = FILE_FOR_CASHLESS[fileStatusIndex]
            setOacHospitalIndex(fileStatusIndex)
            updatedData = {
                oacHospital: temp.isOACHospital,
                fileStatus: temp.fileStatus,
                podNumber: temp.podNumber,
                acknowledgmentReceiptNumber: temp.acknowledgmentReceiptNumber,
                onlineSubmissionReceipt: temp.onlineSubmissionReceipt,
                settlementLetter: temp.settlementLetter,
                dispatchDate: temp.dispatchDate,
                acknowledgementDate: temp.acknowledgementDate,
                onlineSubmissionDate: temp.onlineSubmissionDate,
                settlementDate: temp.settlementDate,
                updatedTime: temp.dateTimeIso,
                updatedBy: temp?.uploadedBy?.useName,
                remarks: temp?.remarks || '',
                [podStatusFile]: temp.statusFileUrl,
            }
        }
        else{
            const fileStatusIndex = POD_STATUS.findIndex((status) => status.value === temp.fileStatus);
            const podStatusFile = POD_STATUS_FILES[fileStatusIndex]
            setOacHospitalIndex(fileStatusIndex)
            updatedData = {
                oacHospital: temp.isOACHospital,
                updatedTime: temp.dateTimeIso,
                updatedBy: temp?.uploadedBy?.useName,
                remarks: temp?.remarks || '',
                [podStatusFile]: temp.statusFileUrl,
                reimbursementPodStatus: temp.fileStatus,
                reimbursementPodNumber: temp.podNumber,
                reimbursementPodCourierName: temp.podCourierName,
            }
        }
        setFormData({ ...formData, ...updatedData });
        setInitialFormData({ ...formData, ...updatedData });
      }

    }, [reportingData])
    
      const handleChange = (name, value) => {
        if(name==="fileStatus" || name==='reimbursementPodStatus'){
            if(payment_mode?.toLowerCase() === 'cashless'){
                const fileStatusIndex = FILE_STATUS.findIndex((status) => status.value === value);
                setOacHospitalIndex(fileStatusIndex)
            }
            else{
                const fileStatusIndex = POD_STATUS.findIndex((status) => status.value === value);
                setOacHospitalIndex(fileStatusIndex)
            }
        }
        setFormData((prevData) => ({
            ...prevData,
            [name]: value,
          }));
    }

    const handleFiles = (files)=>{
        if(files?.length>0){
            const fileData = files.map(item=>({url:item.url}))
            setFormData((prev)=>({...prev, fileProof:fileData}))
            setIsClearedFiles(true)
        }
        else{
            setFormData((prev)=>({...prev, fileProof:[]}))
            setIsClearedFiles(false)
        }
      }
    const handleDisabled = () =>{
        if(payment_mode?.toLowerCase()==='cashless'){
            const requiredFieldKey = UPLOADED_FILE_NUMBER[oacHospitalIndex]?.value;
            const requiredDateKey = UPLOADED_FILE_DATE[oacHospitalIndex]?.value;
            const podStatusFile = FILE_FOR_CASHLESS[oacHospitalIndex]
        if (formData.fileStatus && formData[requiredFieldKey] && formData[requiredDateKey]
             && ((Array.isArray(formData?.fileProof)&& formData?.fileProof?.length>0) || (Array.isArray(formData[podStatusFile])&& formData[podStatusFile]?.length>0))
             && formData.oacHospital) {
            return false
        }
        }
        else{
            const podStatusFile = POD_STATUS_FILES[oacHospitalIndex]
            if(((Array.isArray(formData?.fileProof)&& formData?.fileProof?.length>0) || (Array.isArray(formData[podStatusFile])&& formData[podStatusFile]?.length>0))
                && formData.reimbursementPodStatus 
                && formData.reimbursementPodCourierName
                && formData.reimbursementPodNumber
                && formData.oacHospital
             )
             {
                return false
             }
        }
        return true
    }

    const isFormChanged = () => {
        return JSON.stringify(formData) !== JSON.stringify(initialFormData);
    };

    const  handleSubmitForm = () =>{
        if (!isFormChanged()) {
            toast.info("No changes detected.");
            return;
        }
        
        let payload
        if(payment_mode?.toLowerCase() ==='cashless'){
            const requiredFieldKey = UPLOADED_FILE_NUMBER[oacHospitalIndex]?.value;
            const requiredDateKey = UPLOADED_FILE_DATE[oacHospitalIndex]?.value;
            const podStatusFile = FILE_FOR_CASHLESS[oacHospitalIndex]

            const payload1 = {
                "fileStatus": "",
                "podNumber": "",
                "acknowledgmentReceiptNumber": "",
                "onlineSubmissionReceipt": "",
                "settlementLetter": "",
                "dispatchDate": "",
                "acknowledgementDate": "",
                "onlineSubmissionDate": "",
                "settlementDate": "",
            }
            payload = {
                ...payload1, 
                "fileStatus": formData.fileStatus,
                "statusFileUrl": [...formData[podStatusFile], ...formData?.fileProof],
                [requiredFieldKey]: formData[requiredFieldKey],
                [requiredDateKey]: formData[requiredDateKey],
            };
        }
        else{
            const podStatusFile = POD_STATUS_FILES[oacHospitalIndex]
            payload = {
                "fileStatus": formData.reimbursementPodStatus,
                "podNumber": formData.reimbursementPodNumber,
                "podCourierName": formData.reimbursementPodCourierName,
                "statusFileUrl": [...formData[podStatusFile], ...formData?.fileProof],
            }
        }
        
        const updateParams = {"appId":appId,"updateObj":{'postDischargeDocument':{
            ...payload,
            "paymentMode": payment_mode?.toLowerCase(),
            "isOACHospital": formData.oacHospital,
            "remarks": formData.remarks,
            "actionTakenBy": token,
            "actionTakenByUser": userName,
            "dateTimeIso": currentISODate,
            "source": "hosChatApp"
        },
            "leadId":leadId
        }}
         
        updateCentralReportingData(updateParams).then((response) => {
            if(response.status === 200){
                toast.success(response.message)
                setInitialFormData(formData);
                setFormData(pre=>({...pre, defaultFileProof:[],
                    fileProof:[],
                    fileForPending:[],
                    fileForReimbursement:[],
                    dispatchedFile:[],
                    insOfficeFile:[],
                    onlineSubmissionFile:[],
                    settledFile:[],
                }))
                setIsClearedFiles(false)
            }
        })
        .catch((error) => (console.log(error)));
    }

  return (
    <div class="postDischargeParentContainer">
        <div className='textReadOnlyRow'>
            <span className='textReadOnlyLabel'>Updated Time</span>
            <span className="textReadOnlyValue">{formData.updatedTime ?  formatDate(formData.updatedTime,"DD MMM, hh:mm A") :'--'}</span>
        </div>
        <div className='textReadOnlyRow'>
            <span className='textReadOnlyLabel'>Updated By</span>
            <span className="textReadOnlyValue">{formData.updatedBy || '--'}</span>
        </div>
        <CentralSelect value={formData.oacHospital || ''} label="Is it an OAC Hospital*" options={OAC_HOSPITAL} onChange={(event) => handleChange('oacHospital', event.target.value)}/>
        {
            payment_mode?.toLowerCase() === 'cashless' ? <><CentralSelect value={formData.fileStatus || ''} label="File Status*" options={FILE_STATUS} onChange={(event) => handleChange('fileStatus', event.target.value)}/>
            {
               formData.fileStatus ? <>
                    <CentralInputField value={formData[UPLOADED_FILE_NUMBER[oacHospitalIndex]?.value] || ''} label={UPLOADED_FILE_NUMBER[oacHospitalIndex]?.label + "*"} handleOnChange={(event) => handleChange(UPLOADED_FILE_NUMBER[oacHospitalIndex]?.value, event.target.value)}/>        
                    <CentralDateTimePicker value={formData[UPLOADED_FILE_DATE[oacHospitalIndex]?.value] || ''} label={UPLOADED_FILE_DATE[oacHospitalIndex]?.label + "*"} handleOnChange={(value) => handleChange(UPLOADED_FILE_DATE[oacHospitalIndex]?.value, value)}/>        
                </> :'' 
            }</> : <>
                <CentralSelect value={formData.reimbursementPodStatus || ''} label="POD Status*" options={POD_STATUS} onChange={(event) => handleChange('reimbursementPodStatus', event.target.value)}/>
                <CentralInputField value={formData.reimbursementPodNumber|| ''} label="POD Number*" handleOnChange={(event) => handleChange("reimbursementPodNumber", event.target.value)}/>        
                <CentralInputField value={formData.reimbursementPodCourierName || ''} label="POD Courier Name*" handleOnChange={(event) => handleChange("reimbursementPodCourierName", event.target.value)}/>        
            </>
        }

        <CentralFileUpload label = "Upload File*" uniqueKey="others" isAmountShow={false} isDeletedFile={false} handleChange = {(files)=>handleFiles(files)} defaultFiles = {payment_mode?.toLowerCase() === 'cashless' ? formData[FILE_FOR_CASHLESS[oacHospitalIndex]]:formData[POD_STATUS_FILES[oacHospitalIndex]]} isClearedFiles = {isClearedFiles}/>
        <Box sx={{ marginTop: "20px", width:'100%' }}>
            <Typography
              variant="body1"
              gutterBottom
              sx={{ fontSize: "10px", color: "#1E1E1E" }}
            >
              Remarks (If any)
            </Typography>
            <TextField
              fullWidth
              multiline
              minRows={3}
              defaultValue={formData.remarks}
              InputProps={{
                style: { fontSize: "12px" },
              }}
              InputLabelProps={{
                style: { fontSize: "12px" },
              }}
              placeholder="Describe the remark..."
              onChange={(event) => handleChange('remarks', event.target.value)}
            />
        </Box>
        <div className='accordionSaveButtonContainer' style={{width:'100%'}}>
            <button className={`accordionSaveButton ${handleDisabled() ? "disabledSaveButton":""}`} onClick={handleSubmitForm} disabled={handleDisabled()}>Save</button>
        </div>
    </div>
  )
}
