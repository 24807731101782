import React, { Fragment, useEffect, useRef, useState } from 'react'
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import { toast } from "react-toastify";
import Typography from '@mui/material/Typography';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { renderCentralCommonComponent } from '../../helpers/utilities';
import { useDispatch, useSelector } from 'react-redux';
import usePromiseAllSettled from '../../customHooks/usePromiseAllSettled';
import moment from 'moment';
import { booleanStatesActions, centralReportingDataActions } from '../../../_actions';
import './centralAccordion.css'
import { CentralEquipmentModal } from '../CentralEquipmentModal/CentralEquipmentModal';
import { centralConsumableCategoryService, centralLoadEMRCategoryService, centralResendFeedbackBotService } from '../../../_services';
import { EMR_TYPE_LIST } from '../CentralEquipmentModal/constant';
import { CentralInstructionsAccordion } from '../CentralEquipmentModal/CentralInstructionsAccordion';
import { CentralComplaintsAccordion } from '../CentralComplaintsAccordion/CentralComplaintsAccordion';
import { CentralPostDischarge } from '../CentralPostDischarge/CentralPostDischarge';

export const CentralAccordion = ({type,data,allIds=''}) => {
  const [responses, errors, isLoading, fetchData] = usePromiseAllSettled()
  const dispatch = useDispatch()
  const booleanStates = useSelector(state=>state.booleanStates)
  const [isOpenEquipmentModal, setIsOpenEquipmentModal] = useState(false)
  const [instrumentsOptions, setInstrumentsOptions] = useState([]);
  const [equipmentType, setEquipmentType] = useState('')
  const allInputRef = useRef([]);
  const reportingData = useSelector(state=>state.centralReportingData?.reportingData);
  const complaintsData = useSelector(state=>state.centralComplaintsData?.listData);
  const [noIssueCheck,setNoIssueCheck] = useState(false)
const [globalValues , setGlobalValues] = useState({
  actualOtStartTime:'',
  actualOtEndTime:'',
  isFeedbackBotDisabled:true,
  appointmentId:'',
  feedbackSendCount:'',
  surgeryStatusIns:'',
  provisionalStatus:'',
  isResendDisabled:false
})
let equipmentDetails = useSelector(
  (state) => state.centralInstrumentsData.listData
);

  const handleAccordionBodyRender = (type,data, handleGlobalChanges, setGlobalValues, globalValues)=>{

      return data.map(item=>{
          return (type == item.title && item.details?
                  (<div key = {item.title} className='accordionBodyDataContainer'>
                      {item.details.map(detail => <Fragment key={detail.key}>{renderCentralCommonComponent(detail, handleGlobalChanges, setGlobalValues, globalValues)}</Fragment>)}
                  </div>)
                  :''
                  )
            })
      
  }

  const handleGlobalChanges = (name) => {
    allInputRef.current.push(name);
    allInputRef.current = [...new Set(allInputRef.current)];
  }
  
  const handleSubmit = (event, type)=>{
  event.preventDefault();
    const formData = new FormData(event.target);
    const changedValue = allInputRef.current
    const values = Object.fromEntries(formData.entries());
    let formDataObject = Object.fromEntries([...formData.entries()]);
    function isValidDate(dateString) {
      return moment(dateString, "MM/DD/YYYY hh:mm A", true).isValid();
    }
    
  let cashProofData = [];
  let deletedCashProofData = [];
  let uploadImagesData = []
  for (const property in formDataObject) {
      if (isValidDate(formDataObject[property])) {
          const formattedDate = moment(formDataObject[property], "MM/DD/YYYY hh:mm A").format("YYYY-MM-DDTHH:mm");
          formDataObject[property] = formattedDate;
      }
      if(property.includes('uploadImagesProperty')){
        let dataValue = ''
        let key = ''
        let value = ''
        if(property.includes('databucket')){
          dataValue = property.split(':~')
          key = dataValue[0].split("-")[1]
          value = dataValue[1]
          uploadImagesData.push(value)
          delete formDataObject[property];
        }
        dispatch(booleanStatesActions.updateIsRoomImagesUploaded(false))
          formDataObject[key] = uploadImagesData
      }
        if(property.includes('fileInfoNameProperty')){
          let key = property.split('fileInfoNameProperty-')[1]
          if(property.includes('databucket')){
            cashProofData.push({
              "url": key,
              "amount": formDataObject[property],
              "atTheTimeOf" : type?.toLowerCase() === 'admission' && booleanStates.isUploadedFile ? 'admission' : 'discharge'
            });
          delete formDataObject[property];
        }
        else{
          deletedCashProofData.push(key)
          delete formDataObject[property];
        }
        formDataObject.extraCashProof = {'cashProofData':cashProofData, 'deletedCashProofData':deletedCashProofData};
      }
  }
  const formDataObjectNew = Object.keys(formDataObject).reduce((acc, key) => {
    if (changedValue.some(item=>key.includes(item))) {
        acc[key] = formDataObject[key];
    }
    return acc;
}, {});

    if(booleanStates.isUploadedFile || booleanStates.isUploadedDisFile){
      if(booleanStates.isAmountFillFile && type?.toLowerCase() === 'admission'){
        if(changedValue.includes('totalCollectedOps')){
          delete formDataObjectNew?.totalCollectedOps;
        }
        fetchData(formDataObjectNew, allInputRef)
        dispatch(booleanStatesActions.updateIsAmountFill(false))
        dispatch(booleanStatesActions.updateIsUploaded(false))
      } 
      else if(booleanStates.isAmountFillDisFile && type?.toLowerCase() === 'discharge'){
        if(changedValue.includes('totalCollectedDisOps')){
          delete formDataObjectNew?.totalCollectedDisOps;
        }
        fetchData(formDataObjectNew, allInputRef)
        dispatch(booleanStatesActions.updateIsAmountDisFill(false))
        dispatch(booleanStatesActions.updateIsUploadedDisFile(false))
      }
      else{
          toast.error('Amount should not empty')
      }
    } else if(changedValue.includes('totalCollectedOps') || changedValue.includes('totalCollectedDisOps')){
      const value = formDataObjectNew.totalCollectedOps || formDataObjectNew.totalCollectedDisOps || ''
      if (value !== undefined && value !== null && (value === '' || value == 0)) {
        if(value==''){
          delete formDataObjectNew?.totalCollectedDisOps;
          delete formDataObjectNew?.totalCollectedOps;
        }
        fetchData(formDataObjectNew, allInputRef);
      } 
      else if (value) {
        toast.error('Upload proof if collection > 0');
      }
    }
    else{
      fetchData(formDataObjectNew, allInputRef)
    }
}

const handlePendingFields = () => {
  let totalPendingFields = 0;
  data.forEach(item => {
      let pendingFields = 0;
      if (type === item.title) {
          item && item.details.forEach(element => {
            if (
              (!element.value || (type === "Appointment Details" && element.key==='preAuth_status' && element.value?.toLowerCase() === 'preauth sent') ||
               (Array.isArray(element.value) && element.value.length === 0)) && 
              (element.functionType.includes('input') || element.functionType.includes('multi-select-dropDown'))
            ) {
              if(element.value !==0){
                pendingFields++;
              }
            }
              
          });
          totalPendingFields += pendingFields;
      }
  });
  return totalPendingFields;
};

const handleAddMoreButton = ()=>{
  setIsOpenEquipmentModal(true)
  let category = ''
        let disease = ''
        let doctorId = ''
        let type = ''
        let typeData = []
        if(reportingData && reportingData?.data?.length){
            category = reportingData?.data[0]?.Category?.value
            disease = reportingData?.data[0]?.disease
            doctorId = reportingData?.data[0]?.docAssigned
        }
        
        if(category){
            typeData = EMR_TYPE_LIST.filter(item=>item?.category?.toLowerCase()===category?.toLowerCase())
            type = typeData[0].type
        }
        if(type==='web' && doctorId && category){
            const params = {category:typeData[0].mappedCategory || category.toLowerCase(), doctorId}
            centralLoadEMRCategoryService(params).then(res=>{
                const {status='', result={}} = res;
                setEquipmentType(type)
                  if(status==='Success' && Object.keys(result).length && Object.keys(result.generic).length){
                    setInstrumentsOptions(result.generic)
                  }
                  })
                  .catch(err=>console.log('Fetching category data error', err))
        }
        else if(type==='native' && doctorId && category ){
            const params = {"categoryData":[{"categoryName": category,"disease":disease}],"docId":doctorId}
            centralConsumableCategoryService(params).then(res=>{
                const {status='', result=[]} = res;
                setEquipmentType(type)
                  if(status==='Success' && result.length>0)
                    setInstrumentsOptions(result)
                  })
                  .catch(err=>console.log('Category fetching error', err))
        }
}

const handleCloseModal = () => {
  setIsOpenEquipmentModal(false);
  setInstrumentsOptions([])
};

const handleSubmitMore = () => {
  console.log("Item added");
  // Add logic to handle adding more items
};


const renderPendingFields = ()=>{
  const complaintsPending = (complaintsData && complaintsData?.length>0  && complaintsData[0]?.totalComplaints)
  if(type==='Raise Complaints' && complaintsPending){
    return <Typography className='accordionPendingFiendsText' sx={{color: '#3476CD !important'}}>{"(" +complaintsPending+ ")"}</Typography>
  }
  else if(type==='Raise Complaints' && !complaintsPending && complaintsPendingFields()){
    return <Typography className='accordionPendingFiendsText'>{"(Pending Fields: "+complaintsPendingFields()+")"}</Typography>
  }
  else if(type==='Instruments' && instrumentsPendingFields()){
    return <Typography className='accordionPendingFiendsText'>{"(Pending Fields: "+instrumentsPendingFields()+")"}</Typography>
  }
  else if(handlePendingFields()){
    return <Typography className='accordionPendingFiendsText'>{"(Pending Fields: "+handlePendingFields()+")"}</Typography>
  }
  else{
    return ""
  }
}

const complaintsPendingFields = ()=>{
  let totalCount = 1
  if(!!noIssueCheck){
    totalCount = 0
  }
  return totalCount
}


const instrumentsPendingFields = ()=>{
  let totalPendingCount = 0;
const fieldsToCheck = ["itemSpecification", "quantity", "providedBy", "status", "utilisationStatus"];

const instrumentsArr = equipmentDetails?.length>0 && equipmentDetails[0]?.instruments;
      if (instrumentsArr?.length) {
          instrumentsArr.forEach(item => {
              fieldsToCheck.forEach(field => {
                  if (!item[field] || item[field].trim() === "") {
                      totalPendingCount++;
                  }
              });
          });
      }
return totalPendingCount
}

const renderAccordionBody = () =>{
  if(type === "Instruments"){
    return <CentralInstructionsAccordion/>
  }
  else if(type === 'Raise Complaints'){
    return <CentralComplaintsAccordion setNoIssueCheck={setNoIssueCheck}/>
  }
  else if(type === 'Post Discharge Documents'){
    return <CentralPostDischarge/>
  }
  else{
    return handleAccordionBodyRender(type,data, handleGlobalChanges, setGlobalValues, globalValues)
  }
}

useEffect(() => {
  if(reportingData && reportingData?.data?.length){
    const patientFeedback = reportingData?.data[0]?.patientFeedback
    const appointmentId = reportingData.data[0].appointment_id || reportingData.data[0]._id;
    const provisionalStatus = reportingData.data[0]?.provisionalStatus?.value || '';
    const surgeryStatusIns = reportingData.data[0]?.surgeryStatusIns?.surgeryStatus || '';

    if(patientFeedback?.subStatus && patientFeedback?.subStatus?.toLowerCase()?.includes('not delivered')
      && (!patientFeedback?.sendCount || patientFeedback?.sendCount < 3)){
      setGlobalValues((prev)=>({...prev, isFeedbackBotDisabled:false, feedbackSendCount:patientFeedback?.sendCount}))
    }
    else{
      setGlobalValues((prev)=>({...prev, isFeedbackBotDisabled:true, feedbackSendCount:(patientFeedback?.sendCount || prev?.feedbackSendCount)}))
    }
    setGlobalValues((prev)=>({...prev, appointmentId:appointmentId, provisionalStatus:provisionalStatus, 
      surgeryStatusIns:surgeryStatusIns}))
  }
}, [reportingData])

const handleResendBot = () =>{
  if(!globalValues.isFeedbackBotDisabled){
    centralResendFeedbackBotService((globalValues.appointmentId)).then((res=>{
      if(res.status===200){
        setGlobalValues((prev)=>({...prev, isResendDisabled:true}))
        setTimeout(() => {
          dispatch(centralReportingDataActions.getCentralReportingData({"search_query":globalValues.appointmentId}));
        }, 2000);
      }
      
    })).catch(err=>console.log('Error while resending bot request', err))
  }
}

const renderBottomButton = () =>{
  if(type === "Instruments"){
    return <CentralEquipmentModal
    open={isOpenEquipmentModal}
    onClose={handleCloseModal}
    onAddMore={handleSubmitMore}
    onOpen = {handleAddMoreButton}
    instrumentsOptions = {instrumentsOptions}
    equipmentType = {equipmentType}
  />
  }
  else if(type === "Patient Feedback"){
    return <button onClick={handleResendBot} className={`accordionSaveButton ${(globalValues.isResendDisabled || globalValues.isFeedbackBotDisabled) ? "disabledStyle" :""}`} disabled={ globalValues.isResendDisabled || globalValues.isFeedbackBotDisabled}>Resend {globalValues.feedbackSendCount}</button>
  }
  else if((type !== "Patient Feedback" && type!=="Raise Complaints" && type!=="Post Discharge Documents") &&
  ((type === "Appointment Details" && handlePendingFields() > 0) || type !== "Appointment Details")){
    return <button type="submit" className="accordionSaveButton">Save</button>
  }
}

const getBackgroundColor = () => {
  if(type === 'Appointment Details'){
    if (globalValues.provisionalStatus?.toLowerCase() === 'yes') return 'bisque';
    if (globalValues.surgeryStatusIns?.toLowerCase() === 'not ok for surgery') return 'lavenderblush';
    return 'white';
  }
  else{
    return 'white';
  }
};

  return (
    <Accordion className='accordionContainer'>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon sx={{color:'#3476CD'}}/>}
          aria-controls="panel2-content"
          id="panel2-header"
          className='accordionHeaderContainer'
          style={{background: getBackgroundColor()}}
        >
          <Typography className='accordionHeaderText'>{type}</Typography>
          {renderPendingFields()}
        </AccordionSummary>
        <AccordionDetails className='accordionBodyDetails'>
          <form onSubmit={(e) => handleSubmit(e, type)} autoComplete="off">
            {renderAccordionBody()}
           {
             <div className={`accordionSaveButtonContainer ${ type === "Instruments"? "accordionAddMoreButtonContainer":""}`}>
            {renderBottomButton()}
            </div>
           }
           
          </form>
        </AccordionDetails>
      </Accordion>
  )
}
