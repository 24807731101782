import { toast } from "react-toastify";
import { getTokenFromLocal, handleResponse } from "../../_helpers/handleResponseMiddleware";

let centralBaseUrl = process.env.REACT_APP_CENTRAL_BASE_URL;

export function centralResendFeedbackBotService(params) {
  
    let user = getTokenFromLocal();
    let token = user ? user.access_token : null;
    const requestOptions = {
      method: "GET",
      headers: {
        Authorization: `Bearer ${token}`
      },
    };
    return fetch(
      `${centralBaseUrl}/resend-feedback-bot?appointment_id=${params}`,
      requestOptions
    )
      .then(handleResponse)
   .then(res => {
    if(res.status===200){
        toast.success(res.message || 'Resend IPD feedback bot successfully')
    }
    return res;
   })
      .catch(function (error) {
        console.log({error})
        toast.error(error.message)
      })
      .then(function (response) {
        // always executed
        return response ;
      });
  }
